import React, { FunctionComponent } from "react"

interface TrophyIconProps {}

const TrophyIcon: FunctionComponent<TrophyIconProps> = () => {
  return (
    <svg
      width="104"
      height="91"
      viewBox="0 0 104 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3085_945)">
        <path
          d="M84.834 20H77.334V17.5C77.334 16.1739 76.8072 14.9021 75.8695 13.9645C74.9318 13.0268 73.6601 12.5 72.334 12.5H32.334C31.0079 12.5 29.7361 13.0268 28.7984 13.9645C27.8608 14.9021 27.334 16.1739 27.334 17.5V20H19.8027C18.4767 20 17.2049 20.5268 16.2672 21.4645C15.3295 22.4021 14.8027 23.6739 14.8027 25V30C14.8027 33.3152 16.1197 36.4946 18.4639 38.8388C20.8081 41.183 23.9875 42.5 27.3027 42.5H28.5527C31.584 51.8438 39.8652 58.8438 49.834 59.875V67.5H42.334C41.6709 67.5 41.0351 67.7634 40.5662 68.2322C40.0974 68.7011 39.834 69.337 39.834 70C39.834 70.663 40.0974 71.2989 40.5662 71.7678C41.0351 72.2366 41.6709 72.5 42.334 72.5H62.334C62.997 72.5 63.6329 72.2366 64.1018 71.7678C64.5706 71.2989 64.834 70.663 64.834 70C64.834 69.337 64.5706 68.7011 64.1018 68.2322C63.6329 67.7634 62.997 67.5 62.334 67.5H54.834V59.875C59.7053 59.3966 64.3277 57.4935 68.1237 54.4033C71.9197 51.3132 74.7211 47.173 76.1777 42.5H77.334C80.6492 42.5 83.8286 41.183 86.1728 38.8388C88.517 36.4946 89.834 33.3152 89.834 30V25C89.834 23.6739 89.3072 22.4021 88.3695 21.4645C87.4318 20.5268 86.1601 20 84.834 20ZM27.3027 37.5C25.3136 37.5 23.406 36.7098 21.9994 35.3033C20.5929 33.8968 19.8027 31.9891 19.8027 30V25H27.334V34.7188C27.3385 35.6479 27.3906 36.5762 27.4902 37.5H27.3027ZM84.834 30C84.8258 31.9866 84.0329 33.8895 82.6282 35.2942C81.2235 36.699 79.3206 37.4918 77.334 37.5H77.209C77.2981 36.6697 77.3399 35.835 77.334 35V25H84.834V30Z"
          fill="#FFC200"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3085_945"
          x="0.802734"
          y="2.5"
          width="103.031"
          height="88"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_3085_945"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.760784 0 0 0 0 0 0 0 0 0.32 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3085_945"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3085_945"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default TrophyIcon
