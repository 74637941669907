import React, { FunctionComponent, MouseEventHandler, useEffect } from "react"
import { ArggIcon, CrossIcon, ShareIcon } from "../assets/modalIcons"
import {
  handleMouseDownEvent,
  handleMouseUpEvent,
} from "../utils/buttonClickScaleEffect"
import { DESCRIPTION_METADATA, TITLE_METADATA } from "../utils/constants"

interface MobileArghModalProps {
  showModal: boolean
  handleCloseModal: MouseEventHandler
}

const MobileArghModal: FunctionComponent<MobileArghModalProps> = ({
  showModal,
  handleCloseModal,
}) => {
  useEffect(() => {
    const body = document.querySelector("body")
    if (showModal) {
      body.style.overflow = "hidden"
    } else {
      body.style.overflow = "auto"
    }
  }, [showModal])

  // Move to utils
  const handleShare = (shareDetails: { title; description }) => {
    if (navigator.share) {
      navigator
        .share({
          title: shareDetails.title,
          text: shareDetails.description,
          url: window?.location?.href,
        })
        .then(() => console.log("Successful share"))
        .catch(error => console.log("Error sharing the link", error))
    } else {
      console.log("Share not supported on this browser, do it the old way.")
    }
  }

  const handleEventBubbling = ev => {
    ev.stopPropagation()
  }

  if (showModal) {
    return (
      <div className="modal-bg" onClick={handleCloseModal}>
        <div className="modal" onClick={handleEventBubbling}>
          <div className="cross" onClick={handleCloseModal}>
            <CrossIcon />
          </div>
          <div className="argg-icon">
            <ArggIcon />
          </div>

          <p className="user-msg">
            Thursday is available only on desktop, for now
          </p>

          <p className="create-social-msg">Create a social from desktop</p>

          <button
            className="share-btn"
            onClick={() =>
              handleShare({
                title: TITLE_METADATA,
                description: DESCRIPTION_METADATA,
              })
            }
            onMouseDown={handleMouseDownEvent}
            onMouseUp={handleMouseUpEvent}
          >
            <ShareIcon />
            &nbsp; Share
          </button>
        </div>
      </div>
    )
  }

  return null
}

export default MobileArghModal
