import { ReactComponentLike } from "prop-types"
import React, { FunctionComponent, ReactComponentElement } from "react"
import { AmplitudeEvents } from "../../types/amplitudeEvents"
import { sendAmplitudeData } from "../../utils/amplitude"
import MobileArghModal from "../MobileArghModal"

export interface ModalWrapperProps {
  handleCreateSocial: Function
  handleSignIn: Function
  setIsModalVisible: Function
  handleCTABtnClick: Function
}

const ModalWrapper =
  (Component: ReactComponentLike): FunctionComponent<ModalWrapperProps> =>
  () => {
    const [isModalVisible, setIsModalVisible] = React.useState(false)

    // Todo: Refactor functions to 1 function
    const handleCreateSocial = btnType => () => {
      const thursdayLink = process.env.THURSDAY_SIGNIN_URL
      const joinThursdayLink = `${thursdayLink}?auto=true&templateCode=aba81eeb-6275-4b2f-be80-3fee8082acf5`
      if (window?.innerWidth < 800) {
        sendAmplitudeData(AmplitudeEvents.SHARE_MODAL_OPENED)
        setIsModalVisible(true)
      } else {
        sendAmplitudeData(btnType)
        window.location.href = joinThursdayLink
      }
    }

    const handleSignIn = btnType => () => {
      const thursdayLink = process.env.THURSDAY_SIGNIN_URL

      if (window?.innerWidth < 800) {
        sendAmplitudeData(AmplitudeEvents.SHARE_MODAL_OPENED)
        setIsModalVisible(true)
      } else {
        sendAmplitudeData(btnType)
        window.location.href = thursdayLink
      }
    }

    // Refactor the above function with this
    const handleCTABtnClick =
      ({ btnType, redirectLink, clickEventName }) =>
      () => {
        if (window?.innerWidth < 800) {
          sendAmplitudeData(clickEventName)
          setIsModalVisible(true)
        } else {
          sendAmplitudeData(btnType)
          window.location.href = redirectLink
        }
      }

    return (
      <>
        <MobileArghModal
          handleCloseModal={() => setIsModalVisible(false)}
          showModal={isModalVisible}
        />
        <Component
          setIsModalVisible={setIsModalVisible}
          handleCreateSocial={handleCreateSocial}
          handleSignIn={handleSignIn}
          handleCTABtnClick={handleCTABtnClick}
        />
      </>
    )
  }

export default ModalWrapper
