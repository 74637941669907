import React, { FunctionComponent } from "react"

interface BeerBottleIconProps {}

const BeerBottleIcon: FunctionComponent<BeerBottleIconProps> = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.7511 3.75L23.7511 11.25L17.5011 12.5L4.26668 25.7344C3.80293 26.205 3.54297 26.8393 3.54297 27.5C3.54297 28.1607 3.80293 28.795 4.26668 29.2656L10.7354 35.7344C11.2061 36.1981 11.8403 36.4581 12.5011 36.4581C13.1618 36.4581 13.796 36.1981 14.2667 35.7344L27.5011 22.5L28.7511 16.25L36.2511 6.25"
        fill="#F4B07F"
      />
      <path
        d="M33.7511 3.75L23.7511 11.25L17.5011 12.5L4.26668 25.7344C3.80293 26.205 3.54297 26.8393 3.54297 27.5C3.54297 28.1607 3.80293 28.795 4.26668 29.2656L10.7354 35.7344C11.2061 36.1981 11.8403 36.4581 12.5011 36.4581C13.1618 36.4581 13.796 36.1981 14.2667 35.7344L27.5011 22.5L28.7511 16.25L36.2511 6.25"
        stroke="#F4B07F"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5 2.5L37.5 7.5"
        stroke="#D96716"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15L25 25"
        stroke="#FFDA66"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M9 21L19 31"
        stroke="#FFDA66"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M12 18L22 28"
        stroke="#FFDA66"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 33.75L6.25 23.75"
        stroke="#FFDA66"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BeerBottleIcon
