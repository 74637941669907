import React, { FunctionComponent } from "react"

interface SparkleIconProps {}

const SparkleIcon: FunctionComponent<SparkleIconProps> = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6724 27.4218L18.6724 35.5625C18.5836 35.8017 18.4238 36.008 18.2143 36.1537C18.0048 36.2994 17.7557 36.3775 17.5006 36.3775C17.2454 36.3775 16.9963 36.2994 16.7869 36.1537C16.5774 36.008 16.4175 35.8017 16.3287 35.5625L13.3287 27.4218C13.2654 27.25 13.1655 27.094 13.036 26.9645C12.9066 26.835 12.7505 26.7351 12.5787 26.6718L4.43807 23.6718C4.19884 23.583 3.99253 23.4232 3.84683 23.2137C3.70114 23.0042 3.62305 22.7551 3.62305 22.5C3.62305 22.2448 3.70114 21.9957 3.84683 21.7862C3.99253 21.5768 4.19884 21.4169 4.43807 21.3281L12.5787 18.3281C12.7505 18.2648 12.9066 18.1649 13.036 18.0354C13.1655 17.906 13.2654 17.7499 13.3287 17.5781L16.3287 9.43745C16.4175 9.19823 16.5774 8.99192 16.7869 8.84622C16.9963 8.70053 17.2454 8.62244 17.5006 8.62244C17.7557 8.62244 18.0048 8.70053 18.2143 8.84622C18.4238 8.99192 18.5836 9.19823 18.6724 9.43745L21.6724 17.5781C21.7358 17.7499 21.8356 17.906 21.9651 18.0354C22.0946 18.1649 22.2506 18.2648 22.4224 18.3281L30.5631 21.3281C30.8023 21.4169 31.0086 21.5768 31.1543 21.7862C31.3 21.9957 31.3781 22.2448 31.3781 22.5C31.3781 22.7551 31.3 23.0042 31.1543 23.2137C31.0086 23.4232 30.8023 23.583 30.5631 23.6718L22.4224 26.6718C22.2506 26.7351 22.0946 26.835 21.9651 26.9645C21.8356 27.094 21.7358 27.25 21.6724 27.4218Z"
        fill="#FFC200"
        stroke="#FFC200"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 2.5V10"
        stroke="#FFDA66"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.25 6.25H23.75"
        stroke="#FFDA66"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 11.25V16.25"
        stroke="#FFDA66"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5 13.75H32.5"
        stroke="#FFDA66"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SparkleIcon
