import React, { FunctionComponent } from "react"

interface HeroSectionProps {
  containerStyleClass?: string
}

const HeroSection: FunctionComponent<HeroSectionProps> = ({
  children,
  containerStyleClass,
}) => {
  return <div className={"hero-section " + containerStyleClass}>{children}</div>
}

export default HeroSection
