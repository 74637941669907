import React, { FunctionComponent } from "react"

interface ThursdayPurpleLogoProps {}

const ThursdayPurpleLogo: FunctionComponent<ThursdayPurpleLogoProps> = () => {
  return (
    <svg
      width="161"
      height="42"
      viewBox="0 0 161 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1_3178_1153"
        maskUnits="userSpaceOnUse"
        x="-0.214844"
        y="0"
        width="161"
        height="42"
        fill="black"
      >
        <rect fill="white" x="-0.214844" width="161" height="42" />
        <path d="M13.2452 16.268H17.0972V11.948H13.2452V7.628H7.80916V11.948H4.78516V16.268H7.80916V23.504C7.80916 28.148 10.6172 30.38 14.2892 30.38C15.1892 30.38 16.0532 30.272 16.9532 30.092L16.9172 25.484C16.5572 25.556 16.1252 25.592 15.5492 25.592C14.1452 25.592 13.2452 24.728 13.2452 23.108V16.268Z" />
        <path d="M30.4731 11.588C28.0611 11.588 26.1891 12.884 25.2891 15.008V5H19.7811V30.2H25.2891V20.12C25.2891 17.528 26.5131 16.016 28.5651 16.016C30.6171 16.016 31.8051 17.42 31.8051 19.832V30.2H37.3131V19.004C37.3131 14.54 34.5771 11.588 30.4731 11.588Z" />
        <path d="M51.937 11.948V22.028C51.937 24.656 50.749 26.132 48.769 26.132C46.897 26.132 45.781 24.728 45.781 22.352V11.948H40.273V23.144C40.273 27.608 42.973 30.596 46.933 30.596C49.237 30.596 50.965 29.696 52.009 28.076L51.937 30.2H57.445V11.948H51.937Z" />
        <path d="M67.0013 30.812L71.8973 28.724L69.5213 23.144C67.6853 19.04 68.8013 16.628 71.9333 16.628C72.6533 16.628 73.0493 16.664 73.7333 16.736V11.768C73.1933 11.66 72.5813 11.588 71.9333 11.588C68.8013 11.588 66.8933 13.856 67.0373 17.384H66.9653L64.5533 11.804L59.6573 13.856L67.0013 30.812Z" />
        <path d="M83.9736 30.884C88.6176 30.884 91.9296 28.364 91.9296 24.728C91.9296 21.632 89.6256 19.796 85.3776 18.896L83.6136 18.5C82.4976 18.248 81.9576 17.708 81.9576 16.916C81.9576 15.872 82.9656 15.116 84.3336 15.116C85.9896 15.116 87.3936 16.196 87.4656 17.78L92.4336 17.672C92.3256 13.892 88.9416 11.264 84.3336 11.264C79.8696 11.264 76.6656 13.856 76.6656 17.564C76.6656 20.696 78.5016 22.46 82.4256 23.252L84.1896 23.648C85.7376 24.008 86.5656 24.368 86.5656 25.376C86.5656 26.384 85.5216 27.032 83.9736 27.032C82.0296 27.032 80.7696 25.988 80.7336 24.368L75.7296 24.512C75.7656 28.256 79.1496 30.884 83.9736 30.884Z" />
        <path d="M108.449 5V14C107.153 12.452 105.353 11.588 103.121 11.588C97.9366 11.588 94.3366 15.476 94.3366 21.092C94.3366 26.708 97.9366 30.596 103.121 30.596C105.353 30.596 107.153 29.768 108.449 28.22V30.2H113.957V5H108.449ZM104.345 26.06C101.717 26.06 99.9166 24.044 99.9166 21.092C99.9166 18.14 101.753 16.088 104.345 16.088C106.937 16.088 108.737 18.14 108.737 21.092C108.737 24.044 106.937 26.06 104.345 26.06Z" />
        <path d="M122.669 30.416C125.513 30.416 127.421 29.048 128.141 26.888H128.249C128.249 28.472 128.357 29.588 128.537 30.2H133.865C133.613 28.796 133.505 26.564 133.505 24.62V19.004C133.505 14.54 130.049 11.408 125.081 11.408C120.365 11.408 117.017 14.324 116.837 18.536L122.057 18.644C122.129 16.988 123.353 15.872 125.081 15.872C126.845 15.872 128.033 17.024 128.033 18.716V19.4L124.973 19.688C120.005 20.12 117.017 22.28 117.017 25.556C117.017 28.58 119.177 30.416 122.669 30.416ZM124.793 26.96C123.389 26.96 122.489 26.24 122.489 25.088C122.489 23.648 123.677 22.748 125.729 22.568L128.105 22.316V23.036C128.105 25.232 126.953 26.96 124.793 26.96Z" />
        <path d="M139.337 37.58C143.369 37.58 146.465 35.096 148.373 30.308L155.861 11.948H150.173L145.853 23.684L141.137 11.948H135.305L142.865 29.876L142.577 30.524C141.641 32.468 140.201 32.792 138.401 32.792C137.753 32.792 137.141 32.72 136.817 32.612V37.292C137.537 37.508 138.401 37.58 139.337 37.58Z" />
      </mask>
      <path
        d="M13.2452 16.268H17.0972V11.948H13.2452V7.628H7.80916V11.948H4.78516V16.268H7.80916V23.504C7.80916 28.148 10.6172 30.38 14.2892 30.38C15.1892 30.38 16.0532 30.272 16.9532 30.092L16.9172 25.484C16.5572 25.556 16.1252 25.592 15.5492 25.592C14.1452 25.592 13.2452 24.728 13.2452 23.108V16.268Z"
        fill="#6633FF"
      />
      <path
        d="M30.4731 11.588C28.0611 11.588 26.1891 12.884 25.2891 15.008V5H19.7811V30.2H25.2891V20.12C25.2891 17.528 26.5131 16.016 28.5651 16.016C30.6171 16.016 31.8051 17.42 31.8051 19.832V30.2H37.3131V19.004C37.3131 14.54 34.5771 11.588 30.4731 11.588Z"
        fill="#6633FF"
      />
      <path
        d="M51.937 11.948V22.028C51.937 24.656 50.749 26.132 48.769 26.132C46.897 26.132 45.781 24.728 45.781 22.352V11.948H40.273V23.144C40.273 27.608 42.973 30.596 46.933 30.596C49.237 30.596 50.965 29.696 52.009 28.076L51.937 30.2H57.445V11.948H51.937Z"
        fill="#6633FF"
      />
      <path
        d="M67.0013 30.812L71.8973 28.724L69.5213 23.144C67.6853 19.04 68.8013 16.628 71.9333 16.628C72.6533 16.628 73.0493 16.664 73.7333 16.736V11.768C73.1933 11.66 72.5813 11.588 71.9333 11.588C68.8013 11.588 66.8933 13.856 67.0373 17.384H66.9653L64.5533 11.804L59.6573 13.856L67.0013 30.812Z"
        fill="#6633FF"
      />
      <path
        d="M83.9736 30.884C88.6176 30.884 91.9296 28.364 91.9296 24.728C91.9296 21.632 89.6256 19.796 85.3776 18.896L83.6136 18.5C82.4976 18.248 81.9576 17.708 81.9576 16.916C81.9576 15.872 82.9656 15.116 84.3336 15.116C85.9896 15.116 87.3936 16.196 87.4656 17.78L92.4336 17.672C92.3256 13.892 88.9416 11.264 84.3336 11.264C79.8696 11.264 76.6656 13.856 76.6656 17.564C76.6656 20.696 78.5016 22.46 82.4256 23.252L84.1896 23.648C85.7376 24.008 86.5656 24.368 86.5656 25.376C86.5656 26.384 85.5216 27.032 83.9736 27.032C82.0296 27.032 80.7696 25.988 80.7336 24.368L75.7296 24.512C75.7656 28.256 79.1496 30.884 83.9736 30.884Z"
        fill="#6633FF"
      />
      <path
        d="M108.449 5V14C107.153 12.452 105.353 11.588 103.121 11.588C97.9366 11.588 94.3366 15.476 94.3366 21.092C94.3366 26.708 97.9366 30.596 103.121 30.596C105.353 30.596 107.153 29.768 108.449 28.22V30.2H113.957V5H108.449ZM104.345 26.06C101.717 26.06 99.9166 24.044 99.9166 21.092C99.9166 18.14 101.753 16.088 104.345 16.088C106.937 16.088 108.737 18.14 108.737 21.092C108.737 24.044 106.937 26.06 104.345 26.06Z"
        fill="#6633FF"
      />
      <path
        d="M122.669 30.416C125.513 30.416 127.421 29.048 128.141 26.888H128.249C128.249 28.472 128.357 29.588 128.537 30.2H133.865C133.613 28.796 133.505 26.564 133.505 24.62V19.004C133.505 14.54 130.049 11.408 125.081 11.408C120.365 11.408 117.017 14.324 116.837 18.536L122.057 18.644C122.129 16.988 123.353 15.872 125.081 15.872C126.845 15.872 128.033 17.024 128.033 18.716V19.4L124.973 19.688C120.005 20.12 117.017 22.28 117.017 25.556C117.017 28.58 119.177 30.416 122.669 30.416ZM124.793 26.96C123.389 26.96 122.489 26.24 122.489 25.088C122.489 23.648 123.677 22.748 125.729 22.568L128.105 22.316V23.036C128.105 25.232 126.953 26.96 124.793 26.96Z"
        fill="#6633FF"
      />
      <path
        d="M139.337 37.58C143.369 37.58 146.465 35.096 148.373 30.308L155.861 11.948H150.173L145.853 23.684L141.137 11.948H135.305L142.865 29.876L142.577 30.524C141.641 32.468 140.201 32.792 138.401 32.792C137.753 32.792 137.141 32.72 136.817 32.612V37.292C137.537 37.508 138.401 37.58 139.337 37.58Z"
        fill="#6633FF"
      />
      <path
        d="M13.2452 16.268H17.0972V11.948H13.2452V7.628H7.80916V11.948H4.78516V16.268H7.80916V23.504C7.80916 28.148 10.6172 30.38 14.2892 30.38C15.1892 30.38 16.0532 30.272 16.9532 30.092L16.9172 25.484C16.5572 25.556 16.1252 25.592 15.5492 25.592C14.1452 25.592 13.2452 24.728 13.2452 23.108V16.268Z"
        stroke="#6633FF"
        strokeWidth="8.8"
        strokeLinejoin="round"
        mask="url(#path-1-outside-1_3178_1153)"
      />
      <path
        d="M30.4731 11.588C28.0611 11.588 26.1891 12.884 25.2891 15.008V5H19.7811V30.2H25.2891V20.12C25.2891 17.528 26.5131 16.016 28.5651 16.016C30.6171 16.016 31.8051 17.42 31.8051 19.832V30.2H37.3131V19.004C37.3131 14.54 34.5771 11.588 30.4731 11.588Z"
        stroke="#6633FF"
        strokeWidth="8.8"
        strokeLinejoin="round"
        mask="url(#path-1-outside-1_3178_1153)"
      />
      <path
        d="M51.937 11.948V22.028C51.937 24.656 50.749 26.132 48.769 26.132C46.897 26.132 45.781 24.728 45.781 22.352V11.948H40.273V23.144C40.273 27.608 42.973 30.596 46.933 30.596C49.237 30.596 50.965 29.696 52.009 28.076L51.937 30.2H57.445V11.948H51.937Z"
        stroke="#6633FF"
        strokeWidth="8.8"
        strokeLinejoin="round"
        mask="url(#path-1-outside-1_3178_1153)"
      />
      <path
        d="M67.0013 30.812L71.8973 28.724L69.5213 23.144C67.6853 19.04 68.8013 16.628 71.9333 16.628C72.6533 16.628 73.0493 16.664 73.7333 16.736V11.768C73.1933 11.66 72.5813 11.588 71.9333 11.588C68.8013 11.588 66.8933 13.856 67.0373 17.384H66.9653L64.5533 11.804L59.6573 13.856L67.0013 30.812Z"
        stroke="#6633FF"
        strokeWidth="8.8"
        strokeLinejoin="round"
        mask="url(#path-1-outside-1_3178_1153)"
      />
      <path
        d="M83.9736 30.884C88.6176 30.884 91.9296 28.364 91.9296 24.728C91.9296 21.632 89.6256 19.796 85.3776 18.896L83.6136 18.5C82.4976 18.248 81.9576 17.708 81.9576 16.916C81.9576 15.872 82.9656 15.116 84.3336 15.116C85.9896 15.116 87.3936 16.196 87.4656 17.78L92.4336 17.672C92.3256 13.892 88.9416 11.264 84.3336 11.264C79.8696 11.264 76.6656 13.856 76.6656 17.564C76.6656 20.696 78.5016 22.46 82.4256 23.252L84.1896 23.648C85.7376 24.008 86.5656 24.368 86.5656 25.376C86.5656 26.384 85.5216 27.032 83.9736 27.032C82.0296 27.032 80.7696 25.988 80.7336 24.368L75.7296 24.512C75.7656 28.256 79.1496 30.884 83.9736 30.884Z"
        stroke="#6633FF"
        strokeWidth="8.8"
        strokeLinejoin="round"
        mask="url(#path-1-outside-1_3178_1153)"
      />
      <path
        d="M108.449 5V14C107.153 12.452 105.353 11.588 103.121 11.588C97.9366 11.588 94.3366 15.476 94.3366 21.092C94.3366 26.708 97.9366 30.596 103.121 30.596C105.353 30.596 107.153 29.768 108.449 28.22V30.2H113.957V5H108.449ZM104.345 26.06C101.717 26.06 99.9166 24.044 99.9166 21.092C99.9166 18.14 101.753 16.088 104.345 16.088C106.937 16.088 108.737 18.14 108.737 21.092C108.737 24.044 106.937 26.06 104.345 26.06Z"
        stroke="#6633FF"
        strokeWidth="8.8"
        strokeLinejoin="round"
        mask="url(#path-1-outside-1_3178_1153)"
      />
      <path
        d="M122.669 30.416C125.513 30.416 127.421 29.048 128.141 26.888H128.249C128.249 28.472 128.357 29.588 128.537 30.2H133.865C133.613 28.796 133.505 26.564 133.505 24.62V19.004C133.505 14.54 130.049 11.408 125.081 11.408C120.365 11.408 117.017 14.324 116.837 18.536L122.057 18.644C122.129 16.988 123.353 15.872 125.081 15.872C126.845 15.872 128.033 17.024 128.033 18.716V19.4L124.973 19.688C120.005 20.12 117.017 22.28 117.017 25.556C117.017 28.58 119.177 30.416 122.669 30.416ZM124.793 26.96C123.389 26.96 122.489 26.24 122.489 25.088C122.489 23.648 123.677 22.748 125.729 22.568L128.105 22.316V23.036C128.105 25.232 126.953 26.96 124.793 26.96Z"
        stroke="#6633FF"
        strokeWidth="8.8"
        strokeLinejoin="round"
        mask="url(#path-1-outside-1_3178_1153)"
      />
      <path
        d="M139.337 37.58C143.369 37.58 146.465 35.096 148.373 30.308L155.861 11.948H150.173L145.853 23.684L141.137 11.948H135.305L142.865 29.876L142.577 30.524C141.641 32.468 140.201 32.792 138.401 32.792C137.753 32.792 137.141 32.72 136.817 32.612V37.292C137.537 37.508 138.401 37.58 139.337 37.58Z"
        stroke="#6633FF"
        strokeWidth="8.8"
        strokeLinejoin="round"
        mask="url(#path-1-outside-1_3178_1153)"
      />
      <path
        d="M13.2452 16.268H17.0972V11.948H13.2452V7.628H7.80916V11.948H4.78516V16.268H7.80916V23.504C7.80916 28.148 10.6172 30.38 14.2892 30.38C15.1892 30.38 16.0532 30.272 16.9532 30.092L16.9172 25.484C16.5572 25.556 16.1252 25.592 15.5492 25.592C14.1452 25.592 13.2452 24.728 13.2452 23.108V16.268Z"
        fill="white"
      />
      <path
        d="M30.4731 11.588C28.0611 11.588 26.1891 12.884 25.2891 15.008V5H19.7811V30.2H25.2891V20.12C25.2891 17.528 26.5131 16.016 28.5651 16.016C30.6171 16.016 31.8051 17.42 31.8051 19.832V30.2H37.3131V19.004C37.3131 14.54 34.5771 11.588 30.4731 11.588Z"
        fill="white"
      />
      <path
        d="M51.937 11.948V22.028C51.937 24.656 50.749 26.132 48.769 26.132C46.897 26.132 45.781 24.728 45.781 22.352V11.948H40.273V23.144C40.273 27.608 42.973 30.596 46.933 30.596C49.237 30.596 50.965 29.696 52.009 28.076L51.937 30.2H57.445V11.948H51.937Z"
        fill="white"
      />
      <path
        d="M67.0013 30.812L71.8973 28.724L69.5213 23.144C67.6853 19.04 68.8013 16.628 71.9333 16.628C72.6533 16.628 73.0493 16.664 73.7333 16.736V11.768C73.1933 11.66 72.5813 11.588 71.9333 11.588C68.8013 11.588 66.8933 13.856 67.0373 17.384H66.9653L64.5533 11.804L59.6573 13.856L67.0013 30.812Z"
        fill="white"
      />
      <path
        d="M83.9736 30.884C88.6176 30.884 91.9296 28.364 91.9296 24.728C91.9296 21.632 89.6256 19.796 85.3776 18.896L83.6136 18.5C82.4976 18.248 81.9576 17.708 81.9576 16.916C81.9576 15.872 82.9656 15.116 84.3336 15.116C85.9896 15.116 87.3936 16.196 87.4656 17.78L92.4336 17.672C92.3256 13.892 88.9416 11.264 84.3336 11.264C79.8696 11.264 76.6656 13.856 76.6656 17.564C76.6656 20.696 78.5016 22.46 82.4256 23.252L84.1896 23.648C85.7376 24.008 86.5656 24.368 86.5656 25.376C86.5656 26.384 85.5216 27.032 83.9736 27.032C82.0296 27.032 80.7696 25.988 80.7336 24.368L75.7296 24.512C75.7656 28.256 79.1496 30.884 83.9736 30.884Z"
        fill="white"
      />
      <path
        d="M108.449 5V14C107.153 12.452 105.353 11.588 103.121 11.588C97.9366 11.588 94.3366 15.476 94.3366 21.092C94.3366 26.708 97.9366 30.596 103.121 30.596C105.353 30.596 107.153 29.768 108.449 28.22V30.2H113.957V5H108.449ZM104.345 26.06C101.717 26.06 99.9166 24.044 99.9166 21.092C99.9166 18.14 101.753 16.088 104.345 16.088C106.937 16.088 108.737 18.14 108.737 21.092C108.737 24.044 106.937 26.06 104.345 26.06Z"
        fill="white"
      />
      <path
        d="M122.669 30.416C125.513 30.416 127.421 29.048 128.141 26.888H128.249C128.249 28.472 128.357 29.588 128.537 30.2H133.865C133.613 28.796 133.505 26.564 133.505 24.62V19.004C133.505 14.54 130.049 11.408 125.081 11.408C120.365 11.408 117.017 14.324 116.837 18.536L122.057 18.644C122.129 16.988 123.353 15.872 125.081 15.872C126.845 15.872 128.033 17.024 128.033 18.716V19.4L124.973 19.688C120.005 20.12 117.017 22.28 117.017 25.556C117.017 28.58 119.177 30.416 122.669 30.416ZM124.793 26.96C123.389 26.96 122.489 26.24 122.489 25.088C122.489 23.648 123.677 22.748 125.729 22.568L128.105 22.316V23.036C128.105 25.232 126.953 26.96 124.793 26.96Z"
        fill="white"
      />
      <path
        d="M139.337 37.58C143.369 37.58 146.465 35.096 148.373 30.308L155.861 11.948H150.173L145.853 23.684L141.137 11.948H135.305L142.865 29.876L142.577 30.524C141.641 32.468 140.201 32.792 138.401 32.792C137.753 32.792 137.141 32.72 136.817 32.612V37.292C137.537 37.508 138.401 37.58 139.337 37.58Z"
        fill="white"
      />
    </svg>
  )
}

export default ThursdayPurpleLogo
