import BeerBottleIcon from "../assets/BeerBottle"
import ConfettiIcon from "../assets/ConfettiIcon"
import PeopleIcon from "../assets/peopleIcon"
import PresentationalChartIcon from "../assets/PresentationalChart"
import SparkleIcon from "../assets/SparkleIcon"
import TrophyIcon from "../assets/TrophyIcon"
import { FeatureElement } from "../components/FeaturesSection"
import {
  FeaturesVerticalContent,
  FeaturesVerticalContentType,
} from "../components/FeaturesVertical"
import React from "react"

import FeatBanner1 from "../images/drunkStartups/ds1.png"
import FeatBanner2 from "../images/drunkStartups/ds2.png"
import FeatBanner3 from "../images/drunkStartups/ds3.png"
import ThreePeopleIcon from "../assets/ThreePeopleIcon"

export const mugshotList = [
  {
    number: 1,
    color: "red",
    popover: { content: "can’t stop starting up", textColor: "white" },
  },
  {
    number: 2,
    color: "purple",
    popover: { content: "has crazy ideas every week", textColor: "white" },
  },
  {
    number: 3,
    color: "lightBlue",
    popover: {
      content: "thinks drinks + ideation = next unicorn",
      textColor: "white",
    },
  },
  {
    number: 4,
    color: "green",
    popover: { content: "always up for whiteboarding", textColor: "white" },
  },
]

export const featureSectionContent: FeatureElement[] = [
  {
    mainText: "Team-building",
    subText:
      "Drunk startup helps your team learn how to work & collaborate together in a new way.",
    btnTxt: "Build your team culture",
    logo: <ThreePeopleIcon />,
    btnColor: "purple",
  },
  {
    mainText: "Fun + Engaging",
    subText:
      "Our mixer ensures that each & every member gets an opportunity to express themselves. ",
    btnTxt: "Start the fun",
    logo: <ConfettiIcon />,
    btnColor: "red",
  },
  {
    mainText: "Competitive",
    subText:
      "All the fun while keeping it fresh. Drunk startup can get super competitive.",
    btnTxt: "Let’s get going",
    logo: <TrophyIcon />,
    btnColor: "yellow",
  },
]

export const featureVertical: [
  FeaturesVerticalContent,
  FeaturesVerticalContent
][] = [
  [
    {
      featureVClassName: FeaturesVerticalContentType.Details,
      header: <>Ideate on some craaaaaazy ideas</>,
      subtext:
        "Given a crazy idea, can your team come up with an even more crazier solution in 15 minutes?",
      btnColor: "orange",
      btnTxt: "Get drunk",
      icon: <BeerBottleIcon />,
    },
    {
      featureVClassName: FeaturesVerticalContentType.Banner,
      bannerSrc: FeatBanner1,
    },
  ],
  [
    {
      featureVClassName: FeaturesVerticalContentType.Banner,
      bannerSrc: FeatBanner2,
    },

    {
      featureVClassName: FeaturesVerticalContentType.Details,
      header: <>Pick your moonshot</>,
      subtext:
        "You’ve created quite a few brilliant ideas. Time to mix them up, throw others and finalise on the one.",
      btnColor: "yellow",
      btnTxt: "Register your startup",
      icon: <SparkleIcon />,
    },
  ],
  [
    {
      featureVClassName: FeaturesVerticalContentType.Details,
      header: <>Time to pitch</>,
      subtext:
        "The D-day is here. The time to take it back to the lounge and convince your larger team that your idea is THE ONE!",
      btnColor: "purple",
      btnTxt: "Prepare your pitch",
      icon: <PresentationalChartIcon />,
    },
    {
      featureVClassName: FeaturesVerticalContentType.Banner,
      bannerSrc: FeatBanner3,
    },
  ],
]

export const customCatchPhraseElements = {
  0: {
    bg: "var(--theme-orange)",
    phrase: <>Bring your own drinks</>,
    "btn-Txt": <>Order some booze</>,
    "btn-class": "btn-orangeTxt",
  },
  1: {
    bg: "var(--theme-green)",
    phrase: <>Can you become the next unicorn?</>,
    "btn-Txt": <>Yes we can!</>,
    "btn-class": "btn-greenTxt",
  },
  2: {
    bg: "var(--theme-purple)",
    phrase: <>We are not responsible for any mishaps</>,
    "btn-Txt": <>Sign the waiver</>,
    "btn-class": "btn-purpleTxt",
  },
  3: {
    bg: "var(--theme-yellow)",
    phrase: <>We provide the mixers ;)</>,
    "btn-Txt": <> Mix it up</>,
    "btn-class": "btn-yellowTxt",
  },

  4: {
    bg: "var(--theme-red)",
    phrase: <>Starting up has never been this easy</>,
    "btn-Txt": <>Easy peasy</>,
    "btn-class": "btn-redTxt",
  },

  5: {
    bg: "var(--theme-lightBlue)",
    phrase: <>Only served to 21 and above (not really) </>,
    "btn-Txt": <>Serve some drinks</>,
    "btn-class": "btn-lightBlueTxt",
  },
}
