import React, { MouseEventHandler, ReactElement } from "react"
import RightArrow from "../assets/rightArrow"

export interface FeatureElement {
  logo: ReactElement
  mainText: string
  subText: string
  btnTxt: string
  btnColor?: string
}

interface FeaturesSectionProps {
  content: FeatureElement[]
  featureSectionClassName?: string
  handleBtnClick: MouseEventHandler
}

const FeaturesSection: React.FC<FeaturesSectionProps> = ({
  content,
  featureSectionClassName = "",
  handleBtnClick,
}) => {
  return (
    <div className={"features-section " + featureSectionClassName}>
      <div className="feature-container">
        {content.map((v, ind) => (
          <div className="feature-content" key={ind + v.mainText}>
            <div className="feature-logo-wrapper"> {v.logo}</div>

            <h3 className="feature-title h2Text">{v.mainText}</h3>

            <p className="feature-description body-txt">{v.subText}</p>

            <button
              onClick={handleBtnClick}
              className={`txtBtn-with-arrow-section txt-btn body-txt ${
                v.btnColor || "purple"
              }-txtBtn`}
            >
              <p>{v.btnTxt}</p>
              <RightArrow color={`var(--theme-${v.btnColor || "purple"})`} />
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FeaturesSection
