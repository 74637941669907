import { FunctionComponent, useEffect } from "react"
import Seo from "../../components/seo"
import { DS_TITLE_METADATA } from "../../utils/constants"
import React from "react"
import { MonitorIcon } from "../../assets/monitorIcon"
import LogoHeader from "../../components/LogoHeader"
import MugshotCollection from "../../components/MugshotCollection"
import Footer from "../../components/Footer"
import CatchPhraseSection from "../../components/CatchPhraseSection"
import HeroSection from "../../components/HeroSection"
import "./drunk-startups.css"
import PrimaryBtn from "../../components/PrimaryBtn"
import HeroScribbleDrunkStartups from "../../assets/HeroScribbleDrunkStartups"
import FeaturesSection from "../../components/FeaturesSection"
import PaymentSection from "../../components/PaymentSection"
import AdvantagesSection from "../../components/AdvantagesSection"
import FaqSection from "../../components/FaqSection"
import FeaturesVertical from "../../components/FeaturesVertical"
import ModalWrapper, {
  ModalWrapperProps,
} from "../../components/Wrapper/ModalWrapper"
import {
  customCatchPhraseElements,
  featureSectionContent,
  featureVertical,
  mugshotList,
} from "../../constants/drunkStartups"
import { utmCookieRequest } from "../../utils/handleUTFTracking"
import TemplatesSection from "../../components/TemplatesSection"
import HeroImage from "../../images/drunkStartups/dsHeroImage.png"
import ThursdayPurpleLogo from "../../assets/ThursdayPurpleLogo"
import { AmplitudeEvents } from "../../types/amplitudeEvents"
import RemoteTeams from "../../components/RemoteTeams"
import TwitterWall from "../../components/TwitterWall"
import Testimonials from "../../components/Testimonials"
import OnlyOnDesktop from "../../components/OnlyOnDesktopSection"
import MixersSection from "../../components/MixersSection"
import { sendAmplitudeData } from "../../utils/amplitude"



const DrunkStartups: FunctionComponent<ModalWrapperProps> = ({
  handleCreateSocial,
  handleSignIn,
  setIsModalVisible,
  handleCTABtnClick,
}) => {
  const startDsRedirectLink =
    process.env.THURSDAY_SIGNIN_URL +
    process.env.START_DS_LINK_QUERY_STRING
    
    const handleJoinds = () =>{
      const dsEnvLink = process.env.THURSDAY_CREATE_SOCIAL_URL
      const dslink = `${dsEnvLink}?auto=true&templateCode=5a063bf5-387c-48c9-bf45-d47d69d7a313`
      if (window?.innerWidth < 800) {
        // The body scroll should be disable when the modal is open
        const body = document.querySelector("body")
        body.style.overflow = "hidden"
        sendAmplitudeData(AmplitudeEvents.CREATE_TTAL_FROM_UNIQUEMIXER_PAGE)
        setIsModalVisible(true)
      } else {
        window.location.href = dslink
      }
    }

  useEffect(() => {
    utmCookieRequest()
  }, [])
  const handleJoinDashboard = () => {
    const dashboardLink = process.env.THURSDAY_SIGNIN_URL
    const joinDashboardLink = `${dashboardLink}?auto=true&templateCode=aba81eeb-6275-4b2f-be80-3fee8082acf5`
    sendAmplitudeData(AmplitudeEvents.HANDLE_DASHBOARDPAGE_FROM_FEATUREVERTICAL)
    window.location.href = joinDashboardLink
  }
  return (
    <div className="drunk-startups">
      <Seo
        title={DS_TITLE_METADATA}
        description={DS_TITLE_METADATA}
        canonical={process.env.THURSDAY_UI_BASE_URL + "/drunk-startups"}
      />

      <HeroSection>
        <LogoHeader color="dark" logo="drunk-startups" isLink={true} />

        <div className="heading">
          <div className="h1Text hero-text">
            Is your team crazy enough{" "}
            <MugshotCollection mugshots={mugshotList} />
            <br />
            to come up with the big thing?
          </div>
        </div>

        <div className="thursday-logo-subheading">
          <div className="hero-subText h3Text">
            <p className="text">
              Whiteboarding like you’ve never seen before by{" "}
            </p>

            <a
              href={process.env.THURSDAY_UI_BASE_URL}
              className="thursday-link"
            >
              <ThursdayPurpleLogo />
            </a>
          </div>
        </div>

        <OnlyOnDesktop color="white" />

        <div className="hero-btn-container">
          <PrimaryBtn
            handleOnClick={handleJoinds}
            btnClassName="black-btn"
          >
            Start the fun
          </PrimaryBtn>

          <HeroScribbleDrunkStartups />
        </div>

        <div className="floating-video-container gradient">
          <div className="floating-video-main">
            <div className="floating-video">
              <img src={HeroImage} alt="hero image" />
            </div>
          </div>
        </div>
      </HeroSection>

      <FeaturesSection
        handleBtnClick={handleCTABtnClick({
          btnType: AmplitudeEvents.CREATE_DS_FROM_FEATURE_BTN,
          redirectLink: startDsRedirectLink,
          clickEventName: AmplitudeEvents.SHARE_MODAL_OPENED,
        })}
        content={featureSectionContent}
        featureSectionClassName="feature-horizontal"
      />

      <FeaturesVertical
    
        onClickJoinDashboard={handleJoinDashboard}
          onClickJoinSocial={handleCTABtnClick({
            btnType: AmplitudeEvents.CREATE_DS_FROM_FEATURE_BTN_SECTION2,
            redirectLink: startDsRedirectLink,
            clickEventName: AmplitudeEvents.SHARE_MODAL_OPENED,
          })}
        content={featureVertical}
      />

      <RemoteTeams showGoldenKitty={false} />

      <MixersSection
        title="There's more to Thursday than Drunk startups"
        subTitle="Mixers: Engaging team activities in breakout rooms."
        setIsModalVisible={setIsModalVisible}
      />

      <TemplatesSection
        handleSignIn={handleSignIn}
        handleCreateSocial={handleCreateSocial}
        setIsModalVisible={setIsModalVisible}
      />

      <Testimonials />

      <AdvantagesSection />

      {/* <PaymentSection
        handleCreateSocial={handleCreateSocial}
        handleStart14DaysTrial={handleStart14DaysTrial}
      /> */}

      <FaqSection />

      <TwitterWall bgColor="scarletRed100" title="Launch day love!" />

      <CatchPhraseSection
        customCatchPhraseElements={customCatchPhraseElements}
      />

      <Footer />
    </div>
  )
}

export default ModalWrapper(DrunkStartups)
