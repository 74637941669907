import React, { FunctionComponent, MouseEventHandler } from "react"
import {
  handleMouseDownEvent,
  handleMouseUpEvent,
} from "../utils/buttonClickScaleEffect"

import "./PrimaryBtn.css"

interface PrimaryBtnProps {
  handleOnClick: MouseEventHandler
  btnClassName?: string
}

const PrimaryBtn: FunctionComponent<PrimaryBtnProps> = ({
  handleOnClick,
  btnClassName,
  children,
}) => {
  return (
    <button
      onMouseDown={handleMouseDownEvent}
      onMouseUp={handleMouseUpEvent}
      className={"theme-btn " + btnClassName}
      onClick={handleOnClick}
    >
      {children}
    </button>
  )
}

export default PrimaryBtn
